
//IonInput
import {
  IonPage,
  IonContent,
  toastController,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonImg,
  modalController,
  IonButtons,
  IonButton,
  loadingController,
  alertController,
  actionSheetController,
} from "@ionic/vue";
import { defineComponent, onMounted, onUnmounted, reactive, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Empty from "../../../components/Empty.vue";
import { Share } from "@capacitor/share";
import { DOAMIN_URL } from "../../../common/config";
import ImageDetail from "../../../components/ImageDetail.vue";
import { useStore } from "vuex";
import { Analytics } from "../../../common/analytics";
import services from "../../../services";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    Empty,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonImg,
    IonButtons,
    IonButton,
  },
  props: {
    // postId
    id: {
      type: [Number, String],
      default: () => null,
    },
    level: {
      type: [Number],
      default: () => 1,
    },
    title: {
      type: [String],
      default: () => "",
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const screenName = `PAGE-MISSION_${props.id}`;
    const analytics = new Analytics();
    const state = reactive({
      review: {
        page: 1,
        size: 5,
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        average: 0,
        rating: 0,
      },
      mission: {
        isUserReview: 0,
        loading: true,
        data: {
          rewardState: 0,
          name: "",
          region: "",
          id: 0,
          content: {
            title: "",
            subtitle: "",
            description: "",
            guide: "",
            reward: [
              {
                locationTel: "",
                description: "",
                picture: {
                  name: "",
                  path: "",
                  url: "",
                },
              },
            ],
            besides: "",
          },
          isUserReview: 0
        },
      },
      user: {
        loading: true,
        data: {
          id: 0,
        },
      },
      comment: {
        page: 1,
        size: 20,
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
      },
      contentLoading: true,
      isScrollDisabled: false,
      form: {
        content: "",
      },
    });

    const fetch = async (event: any = null, loadMore = false) => {
      await services.authUser().then(
        (response) => {
          const { data } = response;
          state.user.data = data;
          state.user.loading = false;
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
        }
      );
      if (!loadMore) {
        await services
          .missionFindones(Number(props.id), {
            level: props.level,
          })
          .then(
            (response) => {
              const { data } = response;
              if (event) {
                if (event.type == "ion-refresh") {
                  state.mission.data.id = 0;
                }
              }

              if (data.complete == 0) {
                console.log("미션 완료 안함");
                console.log(data.content.reward[0]);
                if (data.content.reward[0].pictureGray) {
                  if (data.content.reward[0].pictureGray.name) {
                    data.content.reward[0].picture =
                      data.content.reward[0].pictureGray;
                  }
                }
              }
              if (data.completeStampCount < data.acquisitionStampCount) {
                data.acquisitionStampCount = data.completeStampCount;
              }

              if (data.content.guide) {
                data.content.guide = data.content.guide.replace(
                  /\n/g,
                  "<br />"
                );
              }
              if (data.content.description) {
                data.content.description = data.content.description.replace(
                  /\n/g,
                  "<br />"
                );
              }
              if (0 < data.rewardState) {
                if (data.content.reward[0].description) {
                  data.content.reward[0].description =
                    data.content.reward[0].description.replace(/\n/g, "<br />");
                }
              }
              if (data.content.besides) {
                data.content.besides = data.content.besides.replace(
                  /\n/g,
                  "<br />"
                );
              }
              state.review.rating = Number((data.reviewScore / 20).toFixed(1));
              state.review.average = Math.round(state.review.rating) * 20;

              state.mission.data = data;
              state.mission.data.region = data.region.split(",");
              state.mission.loading = false;
            },
            (error) => {
              let message = "";
              try {
                message = error.response.data.message;
              } catch (e) {
                message = "";
                console.log(e);
              }
              console.log(message);
              if (event) {
                if (event.type == "ion-refresh") {
                  state.mission.data.id = 0;
                }
              }
              state.mission.loading = false;
            }
          );
      }

      await services
        .missionReviewFindAll({
          size: state.review.size,
          page: state.review.page,
          missionId: props.id,
        })
        .then(
          (response) => {
            const { data } = response;
            if (event) {
              if (event.type == "ion-refresh") {
                state.review.items = [];
              }
            }

            data.items.some((item: object) => {
              state.review.items.push(item);
            });

            state.review.totalItems = data.totalItems;
            state.review.totalPages = data.totalPages;
            state.review.loading = false;
            state.review.page++;
            if (event) {
              if (data.items?.length == 0 && loadMore) {
                state.isScrollDisabled = true;
              }
            }
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.review.items = [];
              }
            }
            state.review.loading = false;
            state.isScrollDisabled = true;
          }
        );

      // const params = {
      //   size: state.comment.size,
      //   page: state.comment.page
      // };

      // await services.commentFindall(params).then(
      //   response => {
      //     const { data } = response;

      //     if ( event ) {
      //       if ( event.type == "ion-refresh" ) {
      //         state.comment.items = [];
      //       }
      //     }

      //     data.items.some((item: object) => {
      //       state.comment.items.push(item);
      //     });

      //     state.comment.totalItems = data.totalItems;
      //     state.comment.totalPages = data.totalPages;
      //     state.comment.loading = false;
      //     state.comment.page++;
      //     if ( event ) {
      //       if ( data.items.length == 0 && loadMore ) {
      //         state.isScrollDisabled = true;
      //       }
      //     }
      //   },
      //   error => {
      //     let message = '';
      //     try {
      //       message = error.response.data.message;
      //     } catch (e) {
      //       message = '';
      //       console.log(e);
      //     }
      //     console.log(message);
      //     if ( event ) {
      //       if ( event.type == "ion-refresh" ) {
      //         state.comment.items = [];
      //       }
      //     }
      //     state.comment.loading = false;
      //     state.isScrollDisabled = true;
      //   }
      // );

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const onRemoveReview = async (index: number, id: number) => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "삭제 중입니다.",
      });
      await loading.present();
      services
        .missionReviewRemove(id)
        .then((response) => {
          console.log(response);
          if (response.status == 204) {
            // 리뷰를 삭제 했으니 리뷰를 다시 작성할수 있어야 한다.
            state.mission.data.isUserReview = 0;

            setTimeout(function () {
              loading.dismiss();
            }, 1000);
            state.review.items.splice(index, 1);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        })
        .catch((error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        });
    };

    const reportLink = (tpye: string, id: number) => {
      store.commit("report", {
        tpye: tpye,
        id: id,
      });
      router.push({
        name: "SettingReport",
        params: { id: id },
      });
    };

    const presentConfirm = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
          {
            text: "취소",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    async function onReport(index: number, item: any, type: string) {
      const actionSheetButton = [];
      if (type == "review") {
        if (item.user.id == state.user.data.id) {
          actionSheetButton.push({
            text: "삭제하기",
            role: "destructive",
            handler: () => {
              onRemoveReview(index, item.id);
            },
          });
        } else {
          actionSheetButton.push({
            text: "리뷰 신고하기",
            handler: () => {
              reportLink("review", item.id);
            },
          });
        }
      }

      actionSheetButton.push({ text: "취소", role: "cancel" });

      const actionSheet = await actionSheetController.create({
        buttons: actionSheetButton,
      });
      await actionSheet.present();
    }

    //  댓글
    /*
      const onSubmit = async() => {
        const params = {
          content : state.form.content,
          postId : props.id
        };
        services.commentAdd(params)
        .then(
          response => {
              console.log(response);
            },
            error => {
              console.log(error);
            }
        )
      };
    */

    const onMissionStampLink = (id: number, title: string) => {
      store.commit("page", {
        title: title,
      });
      router.push({
        name: "MissionIdStamp",
        params: { id: id, level: props.level },
      });
    };

    const onReviewLink = () => {
      router.push({
        name: "MissionReviewId",
        params: { id: state.mission.data.id },
      });
    };

    const onUserLink = (id: number) => {
      console.log(id);
      /*
      router.push({
        name: "UserId",
        params: {id: id}
      })
      */
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.review.page = 1;
      state.isScrollDisabled = false;
      state.mission.loading = false;
      state.review.loading = false;

      fetch(event);
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    const openToast = async (message: string) => {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    };

    const onShare = async () => {
      if (navigator.share !== undefined) {
        await Share.share({
          title: state.mission.data.content.title,
          text: state.mission.data.content.subtitle,
          url: `${DOAMIN_URL}/mission/${state.mission.data.id}`,
          dialogTitle: `${state.mission.data.content.title} 미션 공유`,
        });
      } else {
        const copyElement = document.createElement("textarea");
        copyElement.value = `${DOAMIN_URL}/mission/${state.mission.data.id}`;
        document.body.appendChild(copyElement);

        copyElement.select();
        document.execCommand("copy");
        document.body.removeChild(copyElement);

        openToast(t("screen.mission.id.notifyCopy"));
      }
    };
    const onImageDetail = async (position = 0) => {
      const items: object[] = [];
      items.push(state.mission.data.content.reward[0].picture);

      const modal = await modalController.create({
        component: ImageDetail,
        componentProps: {
          items: items,
          position: position,
          screenName: `MODAL-IMAGE-PAGE-MISSION_${props.id}`,
        },
      });
      return modal.present();
    };

    // watch
    watch(
      () => route.path,
      () => {
        const review = store.getters["getReview"];
        if (review.write) {
          // 리뷰 갱신
          store.commit("review", {
            write: false
          });

          state.mission.data.isUserReview = 1;

          state.review.page = 1;
          state.review.items = [];
          fetch(null, true);
        }
      }
    );

    // onMounted
    onMounted(() => {
      state.comment.items = [];
      state.review.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    // onUnmounted
    onUnmounted(() => {
      console.log("");
    });

    const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };

    return {
      t,
      state,
      onMissionStampLink,
      onUserLink,
      onClose,
      onShare,
      onLoadData,
      onRefresh,
      onImageDetail,
      onReviewLink,
      onReport,
    };
  },
});
